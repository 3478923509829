import { Controller } from "stimulus"
import $ from "jquery";

export default class extends Controller {
  static targets = ["recipeRow", "recipeRowCheckbox", "selectAll", 'bulkActions', 'bulkActionsButton']

  connect(){
    $(this.bulkActionsTarget).hide();
  }

  toggleRecipeList(e) {
    e.preventDefault()
    const collapseControl = $(e.currentTarget)
    if (collapseControl.attr('data-state') === 'collapsed') {
      collapseControl.attr('data-state', 'expanded');
      $(this.recipeRowTargets).show();
    } else {
      collapseControl.attr('data-state', 'collapsed');
      $(this.recipeRowTargets).hide();
    }
  }

  toggleAll() {
    const selectAllCheckbox = $(this.selectAllTarget);
    selectAllCheckbox.prop("indeterminate", false);
    $(this.recipeRowCheckboxTargets).prop('checked', selectAllCheckbox.prop("checked"));
    if (selectAllCheckbox.prop("checked")) {
      $(this.bulkActionsButtonTarget).show();
    } else {
      $(this.bulkActionsButtonTarget).hide();
      $(this.bulkActionsTarget).hide();
    }
    $(document).trigger('update-enable-all');
  }

  resolveToggleAllState() {
    const selectAll = $(this.selectAllTarget);
    const checkedCount = $(this.recipeRowCheckboxTargets).filter(':checked').length;
    if (checkedCount === 0) {
      selectAll.prop('checked', false);
      this.toggleAll();
    } else if (checkedCount === $(this.recipeRowCheckboxTargets).length) {
      selectAll.prop('checked', true);
      this.toggleAll();
    } else {
      selectAll.prop("indeterminate", true);
      $(this.bulkActionsButtonTarget).show();
      $('#enable-all').prop("indeterminate", true);
    }
  }

  toggleBulk(e){
    e.preventDefault();
    $(this.bulkActionsTarget).toggle();
  }

  setBulk(e){
    e.preventDefault();
    const that = this;
    const bulkActionsController = this.application.getControllerForElementAndIdentifier(this.bulkActionsTarget.parentNode, "synchronize-toggler");
    $(this.recipeRowCheckboxTargets).filter(':checked').each(function() {
      const targetController = that.application.getControllerForElementAndIdentifier(this.closest('tr'), "synchronize-toggler");
      if (bulkActionsController.synchronizeAsNewRadioTarget.checked && targetController.recipeStateValue === 'new') {
        that.setBulkNewRecipe(targetController, bulkActionsController);
      } else if (bulkActionsController.synchronizeAsUpdateTarget.checked && targetController.recipeStateValue === 'existing') {
        that.setBulkUpdateRecipe(targetController, bulkActionsController);
      }
    });
  }

  setBulkNewRecipe(targetController, bulkActionsController){
    targetController.markSynchronizingAsNew();
    targetController.synchronizeAsNewRadioTarget.checked = true;
    targetController.synchronizeDescriptionTarget.checked = bulkActionsController.synchronizeDescriptionTarget.checked;
    targetController.synchronizeEnabledTarget.checked = bulkActionsController.synchronizeEnabledTarget.checked;
    $(targetController.synchronizeCategoryTarget).val($(bulkActionsController.synchronizeCategoryTarget).val()).trigger('change');
    $(targetController.wareCategoryTypeTarget).val($(bulkActionsController.wareCategoryTypeTarget).val()).trigger('change');
  }

  setBulkUpdateRecipe(targetController, bulkActionsController){
    targetController.markSynchronizingAsUpdate();
    targetController.synchronizeAsUpdateTarget.checked = true;
    targetController.synchronizeDescriptionTarget.checked = bulkActionsController.synchronizeDescriptionTarget.checked;
    targetController.synchronizeEnabledTarget.checked = bulkActionsController.synchronizeEnabledTarget.checked;
    targetController.synchronizeNameTarget.checked = bulkActionsController.synchronizeNameTarget.checked;
    targetController.synchronizePriceTarget.checked = bulkActionsController.synchronizePriceTarget.checked;
  }
}
