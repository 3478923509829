import {Controller} from "stimulus"
import $ from "jquery";

export default class extends Controller {
  static targets = ['synchronizeAsNewRadio', 'synchronizeAsPair', 'synchronizeAsUpdate', 'synchronizeAsUnpair',
                    'wareCategoryTypeWrapper', 'wareCategoryType',
                    'synchronizeCheckboxesWrapper', 'synchronizeName', 'synchronizeDescription', 'synchronizePrice', 'synchronizeEnabled',
                    'synchronizeCategory', 'synchronizeCategoryWrapper',
                    'synchronizeRecipe', 'synchronizeRecipeWrapper']

  static values = { recipeState: String }

  connect(){
    if (this.hasSynchronizeAsNewRadioTarget && this.synchronizeAsNewRadioTarget.checked) {
      this.markSynchronizingAsNew();
    }
  }

  markSynchronizingAsNew() {
    $(this.wareCategoryTypeWrapperTarget).show();
    this.wareCategoryTypeTarget.disabled = false;
    this.synchronizeNameTarget.disabled = true;
    this.synchronizeNameTarget.checked = true;
    this.synchronizeDescriptionTarget.disabled = false;
    this.synchronizeDescriptionTarget.checked = true;
    this.synchronizePriceTarget.disabled = true;
    this.synchronizePriceTarget.checked = true;
    this.synchronizeEnabledTarget.disabled = false;
    this.synchronizeEnabledTarget.checked = true;
    $(this.synchronizeCategoryWrapperTarget).show();
    this.toggleSelect2(this.synchronizeCategoryTarget, false, true);

    if (this.hasSynchronizeRecipeWrapperTarget) {
      $(this.synchronizeRecipeWrapperTarget).hide();
      $(this.synchronizeRecipeWrapperTarget).find('.select').removeClass('is-block');
      this.toggleSelect2(this.synchronizeRecipeTarget, true, false)
    }
  }

  markSynchronizingAsPair() {
    $(this.wareCategoryTypeWrapperTarget).hide();
    this.wareCategoryTypeTarget.disabled = true;
    this.synchronizeNameTarget.disabled = false;
    this.synchronizeNameTarget.checked = false;
    this.synchronizeDescriptionTarget.disabled = false;
    this.synchronizeDescriptionTarget.checked = false;
    this.synchronizePriceTarget.disabled = false;
    this.synchronizePriceTarget.checked = false;
    this.synchronizeEnabledTarget.disabled = false;
    this.synchronizeEnabledTarget.checked = false;
    $(this.synchronizeCategoryWrapperTarget).hide();
    this.toggleSelect2(this.synchronizeCategoryTarget, true, false);

    if (this.hasSynchronizeRecipeWrapperTarget) {
      $(this.synchronizeRecipeWrapperTarget).show();
      $(this.synchronizeRecipeWrapperTarget).find('.select').addClass('is-block');
      this.toggleSelect2(this.synchronizeRecipeTarget, false, true);
    }

    if (this.hasWareCategoryTypeWrapperTarget) {
      $(this.wareCategoryTypeWrapperTarget).hide();
      this.wareCategoryTypeTarget.disabled = true;
    }
  }

  markSynchronizingAsUpdate() {
    $(this.synchronizeCheckboxesWrapperTarget).show();
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeNameTarget, false, true)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeDescriptionTarget, false, true)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizePriceTarget, false, true)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeEnabledTarget, false, true)
    if (this.hasSynchronizeCategoryWrapperTarget) {
      $(this.synchronizeCategoryWrapperTarget).hide();
      this.toggleSelect2(this.synchronizeCategoryTarget, true, false);
    }
  }

  markSynchronizingAsUnpair() {
    $(this.synchronizeCheckboxesWrapperTarget).hide();
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeNameTarget, true, false)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeDescriptionTarget, true, false)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizePriceTarget, true, false)
    this.toggleDisableAndVisibilityOfCheckbox(this.synchronizeEnabledTarget, true, false)
  }

  toggleDisableAndVisibilityOfCheckbox(element, disable, visibility) {
    element.disabled = disable;
    if (visibility) {
      $(element).closest('label').show();
    } else {
      $(element).closest('label').hide();
    }
  }

  toggleSelect2(element, disable, visibility) {
    element.disabled = disable;
    if (visibility) {
      $(element).next(".select2").show();
    } else {
      $(element).next(".select2").hide();
    }
  }
}
