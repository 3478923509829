import $ from "jquery";

class Checkboxer {
  constructor() {
    $(document).on('click', "#enable-all", function (e) {
      $('#enable-all').prop("indeterminate", false);
      $('[data-check]').prop('checked', $(this).prop("checked"));

      // Stimulus doesnt handle jQuery events by trigger, so we need to use vanila events
      const changeEvent = document.createEvent('Event');
      changeEvent.initEvent('input', true, false);
      const sectionCheckboxes = document.querySelectorAll('*[data-check]');
      for (const sectionCheckbox of sectionCheckboxes) {
        sectionCheckbox.dispatchEvent(changeEvent);
      }
    });

    // Activate all
    $(document).on('update-enable-all', function (e) {
      const sectionCheckboxes = document.querySelectorAll('*[data-check]');
      for (const sectionCheckbox of sectionCheckboxes) {
        if (sectionCheckbox.indeterminate) {
          $('#enable-all').prop("indeterminate", true).prop('checked', false);
          return;
        }
      }

      const $checkboxes = $('[data-check]');
      if ($checkboxes.filter(':checked').length === 0) {
        $('#enable-all').prop("indeterminate", false).prop('checked', false)
      } else if ($checkboxes.length === $checkboxes.filter(':checked').length) {
        $('#enable-all').prop("indeterminate", false).prop('checked', true)
      } else {
        $('#enable-all').prop("indeterminate", true).prop('checked', false);
      }
    });
  }
}

export {Checkboxer};
