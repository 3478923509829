import $ from "jquery";

class SyncActionSelect {
    constructor() {
        $(document).on('change', "select[id^=categories_ware_categories_form_categories_ware_categories_attributes_][id$=_action], select[id^=recipes_products_form_recipes_products_attributes_][id$=_action]", function (e) {
            let selected = parseInt(this.value);
            let parent = $(e.target).parents('.columns');
            let info = parent.find(".column:last-child > .info");
            let formField = parent.find(".column:last-child > .field");

            if (selected === -1) { // new product/category
                info.show();
                formField.show();
            } else {
                info.hide();
                formField.hide();
            }
        });
    }
}

export {SyncActionSelect};
