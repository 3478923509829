// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from "jquery";

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
const jQuery = require("jquery");

import {Notifications} from "../components/bulma/notifications";
import {SyncActionSelect} from "../components/sync_action_select";
import {Select2} from "../components/select2";
import { Checkboxer } from "../components/checkboxer";

import '../stylesheets/application.scss'

window.jQuery = jQuery;
window.$ = $;
new Notifications();


$(document).on('turbolinks:load', function () {
    new Select2();
    new SyncActionSelect();
    new Checkboxer();
});

import '../stylesheets/application.scss'
import '../images/dotykacka-logo.svg'
import '../images/speedlo-logo.png'

// Stimulus

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context));

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
